import styles from './ExampleTemplate.module.scss';

import trophyIcon from '../images/trophy.svg';
import placeholderImage from './images/1110x620.png';

function Trueet() {
  return (
    <>
      <div className={styles['main-container']}>
        <div className={styles['mobi-padding']}>
          <h2 className={styles['title-primary']}>Trueet</h2>
          <h2 className={styles['subtitle-primary']}>
          A notícia falsa está circulando. Avise seus amigos.
          </h2>

          <div className={styles['info-credits-outer-container']}>
            <div className={styles['info-credits-inner-container']}>
              <div className={styles['info-credits-client']}>
                GLOBAL EDITORS NETWORK • 2018
              </div>

              <div className={styles['info-credits-category-title']}>
                Pesquisa e produção
              </div>
              <div className={styles['info-credits-category-collaborators']}>
                Luís Guilherme Julião, Mateus Paulino e Vinicius Machado
              </div>

              <div className={styles['info-credits-category-title']}>
                Wireframe
              </div>
              <div className={styles['info-credits-category-collaborators']}>
                Vinicius Machado
              </div>

              <div className={styles['info-credits-prize-container']}>
                <div className={styles['info-credits-prize-icon']}>
                  <img src={trophyIcon} alt="Troféu" />
                </div>

                <div className={styles['info-credits-prize-info']}>
                  <div className={styles['info-credits-prize-title']}>
                    EditorsLab - Google News Initiative
                  </div>
                  <div className={styles['info-credits-prize-description']}>
                    2018 • 1º Lugar
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className={styles['text-primary']}>
          O turbulento cenário político do Brasil próximo às eleições de 2018 fizeram com que a Global Editors Network (GEN) desembarcasse pela primeira vez no país com um desafio: encontrar ideias para combater informações falsas que circulavam livremente nas redes. Para isso, a GEN reuniu equipes formadas por jornalistas, designers e desenvolvedores de diferentes veículos de mídia, startups e universidades no EditorsLab — um hackaton promovido pela Google News Initiative. A competição foi em São Paulo e durou dois dias inteiros, desde a pesquisa até a solução e apresentação da proposta para os jurados. Com o projeto Trueet, nossa equipe ficou em 1º lugar e representou o Brasil no GEN Summit 2018, em Lisboa, Portugal.
          </p>
        </div>
      </div>

      <div className={styles['content-image-container']}>
        <img
          className={styles['content-image']}
          src={placeholderImage}
          alt="Descrição da imagem"
        />
      </div>

      <div className={styles['main-container']}>
        <div className={styles['mobi-padding']}>
          <h3 className={styles['title-secondary']}>
            Foco de dis/misinformation
          </h3>
          <p className={styles['text-primary']}>
          A pesquisa partiu de duas reflexões: 1) Em que ponto uma informação falsa ganha tração e passa a ser compartilhada em massa? e 2) Por qual motivo alguém decide compartilhar uma informação falsa? Para tentar entender esta dinâmica em pouco tempo, recorremos a materiais de análise já consolidados, como o paper `&quot;`Robôs, redes sociais e política no Brasil`&quot;`, da FGV, e o relatório `&quot;`The spread of low-credibility content by social bots`&quot;`, da Universidade de Indiana (EUA), que monitorou as `&quot;`fake news`&quot;` nas eleições norte-americanas em 2016. Chegamos então em algumas características do problema a ser combatido:<br/><br/>
          • Existem ao menos quatro tipos de usuários que colaboram, de forma intencional ou não, para a disseminação de notícias falsas: <strong>os bots</strong>, que aumentam o volume da mesma mensagem repetidamente; <strong>contas falsas</strong> controladas por humanos, que têm a preocupação de se passar por usuários reais mesclando publicações de trivialidades com as que têm a intenção de influenciar um debate, criando vínculos; <strong>contas reais</strong> (pessoais ou institucionais) má-intencionadas ou irresponsáveis com poder de influência; e <strong>leigos</strong>, que não necessariamente têm a intenção de influenciar um debate, mas são expostos às informações falsas e as repassam.<br/><br/>
            • Um caminho comum da disseminação de informação falsa é: fazer volume para atingir o maior número possível de pessoas → ser notado e repassado por perfis influentes e com grande número de seguidores → ser repassado com capilaridade para conhecidos.<br/><br/>
            • Pessoas tendem a compartilhar mais as informações quando estas são enviadas por pessoas próximas -amigos e parentes- ou perfis pelos quais têm apreço e confiança.<br/><br/>
            • O Twitter é um ambiente favorável para o início da cadeia de disseminação de informações falsas, pela facilidade de sua API que permite a criação de bots, por manter certo anonimato nos perfis e por ser a interface com o público de muitos influenciadores, políticos e artistas.<br/><br/>
            • O combate à desinformação nas redes é um trabalho de múltiplas vias: é preciso ter regulação institucional, mas também trabalho colaborativo dos próprios usuários.<br/><br/>
          </p>
        </div>
      </div>

      <div className={styles['content-image-container']}>
        <img
          className={styles['content-image']}
          src={placeholderImage}
          alt="Descrição da imagem"
        />
      </div>

      <div className={styles['main-container']}>
        <div className={styles['mobi-padding']}>
          <p className={styles['text-primary']}>
          A partir destes itens, elaboramos algumas premissas voltadas para a solução:
          • É preciso interromper ou mitigar a cadeia de disseminação de informação falsa em algum ponto — sobretudo nas contas reais.<br/><br/>
          • É preciso focar na rede de confiança -amigos, parentes e influenciadores-.<br/><br/>
          • É fundamental `&quot;`vacinar`&quot;` os usuários com as informações verdadeiras, para que eles possam reconhecer as falsas com antecedência.<br/><br/>
          • Quanto maior o volume de mensagens verdadeiras frente às mensagens disparadas por bots maliciosos, melhor. As checkagens precisam disputar espaço na narrativa das redes.<br/><br/>
          • O usuário com a informação verdadeira precisa multiplicar seu alcance.
          </p>
        </div>
      </div>

      <div className={styles['main-container']}>
        <div className={styles['mobi-padding']}>
          <h3 className={styles['title-secondary']}>
            True + Tweet
          </h3>
          <p className={styles['text-primary']}>
            <strong>Trueet</strong> é a ideação de um projeto feito com o objetivo de inundar o Twitter com notícias verdadeiras em reação às falsas que circulam. Mais do que um radar de notícias falsas, operado por jornalistas especializados e bots que monitoram as timelines, a ideia é potencializar o alcance individual daqueles que estão munidos com as checkagens. Um `&quot;`bot do bem`&quot;`, em uma guerra de volume e confiança.</p>
          <p className={styles['text-primary']}>
            O projeto é um Progressive Web App (PWA), uma aplicação desenvolvida pelo próprio Google que possui três principais vantagens: não é necessário fazer download, não ocupa espaço e não necessita de autenticação do usuário.</p>
        </div>
      </div>

      <div className={styles['content-image-container']}>
        <img
          className={styles['content-image']}
          src={placeholderImage}
          alt="Descrição da imagem"
        />
      </div>

      <div className={styles['main-container']}>
        <div className={styles['mobi-padding']}>
        <h3 className={styles['title-secondary']}>
            Como funciona
          </h3>
        <p className={styles['text-primary']}>
          O usuário ativa as notificações na interface principal. Este primeiro passo não é obrigatório, mas esclarecemos que o objetivo não é publicitário. A ideia é possibilitar a reação a um boato que está em alta o mais rápido possível. A projeção é de que o usuário receba até três notificações por semana. Estas notificações trazem notícias falsas que circulam através de bots ou perfis reais e que começaram a ganhar tração.</p>
        <p className={styles['text-primary']}>
          Acessando uma das notícias falsas, através da notificação recebida ou da home, o usuário pode ler o boato e na sequência a informação correta. Este `&quot;`monitoramento`&quot;` do ambiente de notícias falsas já é uma expertise das redações, que já contam com equipes focadas em encontrar e verificar as informações. São estes profissionais de mídia e start-ups, junto com suas tecnologias e apoio de seus veículos, que manterão a timeline do Trueet, abrindo, inclusive, mais uma porta para seus negócios, visto que o usuário terá acesso também às suas páginas. Uma equipe do próprio Trueet fará a mediação, para evitar repetições e veículos não-cadastrados.</p>
        <p className={styles['text-primary']}>
          O bot Trueet então entra em cena: com um clique, o usuário faz uma varredura em sua própria timeline para ver quais perfis que ele segue estão compartilhando este boato. Esta identificação será feita através de palavras-chaves em cadeia (uma vez que os boatos quase sempre possuem a mesma estrutura textual). O usuário então pode selecionar rapidamente todos os perfis (ou quais desejar) e enviar, de uma só vez, um reply desmentindo a notícia falsa no perfil de quem a está projetando.
        </p>
        <p className={styles['text-primary']}>
          Se nenhum perfil for identificado por compartilhar a notícia falsa, o usuário tem ainda a opção de fazer um post público alertando do boato com antencedência, para que seus seguidores já estejam sabendo de antemão quando, porventura, entrarem em contato com ele.
        </p>
      </div>
    </div>

      <div className={styles['content-image-container']}>
        <img
          className={styles['content-image']}
          src={placeholderImage}
          alt="Descrição da imagem"
        />
      </div>

      <div className={styles['main-container']}>
        <div className={styles['mobi-padding']}>
        <h3 className={styles['title-secondary']}>
            Conclusão
          </h3>
        <p className={styles['text-primary']}>
          O hackaton promovido pela GEN, mais do que a premiação em si, foi uma oportunidade de aprender e trocar com inúmeros profissionais e pesquisadores de excelência. Participaram da competição veículos como G1, Aos Fatos, Nexo, Infoglobo, Zero Hora, USP, Voz das Comunidades, Agência Mural, Cásper Líbero e PUC-Rio. Além dos competidores, os jurados e monitores tiveram papel fundamental para direcionar os projetos e colocar sempre no centro do debate a importância deles, justo às vésperas de uma das eleições mais acirradas da história do país.
          </p>
          <p className={styles['text-primary']}>
          Trueet, além de levar o prêmio principal, abriu diálogo na comunidade, nas equipes e foi reconhecido em outros veículos. Leia mais na <a href="https://exame.com/brasil/3-ideias-que-vao-te-ajudar-a-combater-fake-news-no-futuro-proximo/" target="_blank" rel="noreferrer">Exame</a>, na página da GEN, <a href="https://medium.com/editors-lab-impact/a-year-of-journalism-hackathon-innovation-3b5d67e8649d" target="_blank" rel="noreferrer">aqui</a> ou <a href="https://medium.com/editors-lab-impact/globoeditorslab-2780e0061cf3" target="_blank" rel="noreferrer">aqui</a>.
          </p>
        </div>
      </div>


    </>
  );
}

export default Trueet;
